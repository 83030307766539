import React from 'react'

export function CarouselScroller({children}){
    return (
        <div className="flex justify-center items-center">
            <button className="left-carousel-button" />
            <div className="flex-1">{children}</div>
            <button className="left-carousel-button rotate-180 transform" />
        </div>
    )
}
