import React from "react"

import { CarouselScroller } from "../components/carousel-scroller"
import { CustomHowWeDoIt } from "../components/how-we-do-it"
import Layout from "../components/layout"
import { PurpleHeroSection } from "../components/purple-hero-sections"
import SEO from "../components/seo"
import { OtherServices } from "./product-strategy"

const HeroSection = () => (
  <PurpleHeroSection
    titleFirst={"E-commerce"}
    titleSecond={"Solutions"}
    subtitle={"Your business without walls"}
    content={
      "Your website should be a business asset, not just a storefront. Touchcore is a specialist ecommerce agency, and we design and buildwebsites with user-friendly navigation, seamless mobile-responsive   designs, and a conversion-optimised checkout process."
    }
    image={require("../assets/services-i-aug.svg")}
  />
)
const ERPSolutions = () => (
  <section className="container px-5 md:px-12">
    <h3 className="text-center text-2xl font-black text-pgray my-8 md:w-8/12 mx-auto">
      Custom Ecommerce Website Solutions that Generate Sales for Your Business
    </h3>
    <CarouselScroller>
      <img
        className="mx-auto"
        src={require("../assets/touchcore-rj4-website-Design.png")}
      />
    </CarouselScroller>
  </section>
)
const HowWeDoIt = () => {
  const data = [
    {
      title: "Identify",
      content:
        "We think first like you.This will help us in understanding your brand, needs, business objectives and goals for your website, and developing an appropriate timeline and project plan.",
    },
    {
      title: "Research",
      content:
        "We dive in and research extensively on your industry, competitors, audience, and interview internal stakeholders to construct a tailored strategy and design.",
    },
    {
      title: "Content Strategy",
      content:
        "Working hand in gloves with your team, we ascertain what content is necessary and of great value for your website as well as where it should be placed for maximum impact.",
    },
    {
      title: "Design",
      content:
        "We style the graphics and visual parts required to bring your web site to life.",
    },
  ]
  return <CustomHowWeDoIt data={data} />
}
const ECommerceSolutions = () => (
  <Layout>
    <SEO title="E-Commerce Development company | Touchcore Technology Nigeria" description="Your website should be a business asset, not just a storefront. Touchcore is a specialist ecommerce agency that delivers seamless experiences for your customers" />
    <HeroSection />
    <ERPSolutions />
    <HowWeDoIt />
    <OtherServices />
  </Layout>
)

export default ECommerceSolutions
